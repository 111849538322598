import gql from 'graphql-tag';
import { useApolloClient } from '@keap-web/apollo-lib';

export const useInstallQueries = () => {
    const apolloClient = useApolloClient();

    const getRequestInfo = (installRequestId) => {
        return apolloClient.query({
            query: gql`
                query installRequest($installRequestId: ID!) {
                    installRequest(installRequestId: $installRequestId) {
                        title
                        description
                        linkId
                        resourceType
                        tenantId
                        status
                        dependencies {
                            title
                            description
                            resourceType
                            resourceSubtype
                            resourceId
                        }
                        company {
                            name
                            logoUrl
                        }
                        topLevel
                        sharedResourceId
                        updateTime
                        createTime
                        failureReasons
                    }
                }
            `,
            variables: {
                installRequestId,
            },
            fetchPolicy: 'no-cache',
        });
    };

    const getLinkInfo = (linkId) => {
        return apolloClient.query({
            query: gql`
                query linkInfo($linkId: ID!) {
                    linkInfo(linkId: $linkId) {
                        dependencies {
                            title
                            description
                            resourceType
                            resourceSubtype
                        }
                        company {
                            name
                            logoUrl
                        }
                        title
                        description
                        isValid
                        linkId
                        resourceType
                        ownerType
                    }
                }
            `,
            variables: {
                linkId,
            },
            fetchPolicy: 'no-cache',
        });
    };

    const createBatchInstallRequest = ({ tenantIds, sharedResourceId }) => {
        return apolloClient.mutate({
            mutation: gql`
                mutation createBatchInstallRequest($request: CreateBatchInstallRequest!) {
                    createBatchInstallRequest(request: $request) {
                        batchRequestId
                        failedTenantIds
                    }
                }
            `,
            variables: {
                request: {
                    tenantIds,
                    sharedResourceId,
                },
            },
        });
    };

    const uninstall = (requestId) => {
        return apolloClient.mutate({
            mutation: gql`
                mutation uninstallRequest($requestId: ID!) {
                    uninstallRequest(requestId: $requestId) {
                        dependencies {
                            title
                            resourceType
                            resourceSubtype
                            resourceId
                            uninstallSuccess
                            unsupported
                        }
                    }
                }
            `,
            variables: {
                requestId,
            },
        });
    };

    return {
        getRequestInfo,
        getLinkInfo,
        uninstall,
        createBatchInstallRequest,
    };
};
