import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useInstallQueries } from '../../install/api/install';
import { REQUEST_STATUSES } from '../../constants/share.constants';

export const useInstallRequestStore = defineStore('installRequest', () => {
    const dependencyList = ref([]);
    const uninstalledDependencyList = ref([]);
    const batchFailedTenantIds = ref([]);
    const installItem = ref({});
    const partnerInfo = ref({});
    const queries = useInstallQueries();

    function reset() {
        dependencyList.value = [];
        uninstalledDependencyList.value = [];
        batchFailedTenantIds.value = [];
        installItem.value = {};
        partnerInfo.value = {};
    }

    async function fetchFromLink(linkId) {
        const { data: { linkInfo } } = await queries.getLinkInfo(linkId);

        dependencyList.value = linkInfo.dependencies || [];
        partnerInfo.value = linkInfo.company;
        installItem.value = {
            ...linkInfo,
            company: undefined,
        };
    }

    async function fetchFromRequest(requestId) {
        const { data: { installRequest } } = await queries.getRequestInfo(requestId);

        partnerInfo.value = installRequest.company;
        dependencyList.value = installRequest.dependencies || [];
        installItem.value = {
            ...installRequest,
            company: undefined,
            dependencies: undefined,
        };
    }

    async function uninstall(requestId) {
        const { data: { uninstallRequest } } = await queries.uninstall(requestId);

        uninstalledDependencyList.value = uninstallRequest.dependencies || [];

        if (uninstalledDependencyList.value.some(({ uninstallSuccess }) => uninstallSuccess)) {
            installItem.value.status = REQUEST_STATUSES.UNINSTALLED;
            installItem.value.updateTime = new Date().toISOString();
        }
    }

    async function createBatchInstallRequest({ tenantIds, sharedResourceId }) {
        const dedupeTenantIds = [...new Set(tenantIds)];

        const { data: { createBatchInstallRequest: response } } = await queries.createBatchInstallRequest({ tenantIds: dedupeTenantIds, sharedResourceId });

        batchFailedTenantIds.value = response?.failedTenantIds;
    }

    return {
        dependencyList,
        uninstalledDependencyList,
        installItem,
        partnerInfo,
        batchFailedTenantIds,

        reset,
        fetchFromLink,
        fetchFromRequest,
        uninstall,
        createBatchInstallRequest,
    };
});
